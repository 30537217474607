<template>
    <div>
        <div class="mt-5 grid gap-1 grid-cols-2 sm:mt-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div v-for="(image, index) in images">
                <a href="#" @click.prevent="showLightbox(index)">
                    <img class="" :src="image" alt="Plaza View" title=""/>
                </a>
            </div>

        </div>
        <div class="bg-black bg-opacity-80 h-screen w-full
         fixed left-0 top-0 flex justify-center items-center" v-if="visible" @click="hideLightbox">
            <div>
                <div class="relative top-0 right-0 text-white cursor-pointer text-4xl mr-8 text-right" @click.stop="hideLightbox">&times;</div>
                <div class="flex">
                    <div class="cursor-pointer self-center pl-8"
                         @click.stop="prev"
                         :class="{'invisible': ! hasPrev()}">
                        <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                            <path d="M0-.5h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                    <div class="" @click.stop="">
                        <img :src="images[index]">
                    </div>
                    <div class="cursor-pointer self-center pr-8"
                         @click.stop="next"
                         :class="{'invisible': ! hasNext()}">
                        <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                            <path d="M0-.25h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            visible: false,
            index: 0,
            images: [
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/1.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/2.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/3.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/4.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/5.jpg',

                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/6.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/7.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/8.jpg',
                'https://ncca-assets.s3.eu-west-2.amazonaws.com/gallery/9.jpg',
            ]
        }
    },

    methods: {
        showLightbox(index) {
            this.visible = true;
            this.index = index;
        },

        hideLightbox() {
            this.visible = false;
            this.index = 0;
        },

        hasNext() {
            return this.index + 1 < this.images.length;
        },
        hasPrev() {
            return this.index - 1 >= 0;
        },

        prev() {
            if (this.hasPrev()) {
                this.index -= 1;
            }
        },
        next() {
            if (this.hasNext()) {
                this.index += 1;
            }
        },
        onKeydown(e) {
            if (this.visible) {
                switch (e.key) {
                    case 'ArrowRight':
                        this.next();
                        break;
                    case 'ArrowLeft':
                        this.prev();
                        break;
                    case 'ArrowDown':
                    case 'ArrowUp':
                    case ' ':
                        e.preventDefault();
                        break;
                    case 'Escape':
                        this.hideLightbox();
                        break;
                }
            }
        },
    },

    mounted() {
        window.addEventListener('keydown', this.onKeydown)
    },
    destroyed() {
        window.removeEventListener('keydown', this.onKeydown)
    },
}
</script>

<style scoped>
</style>
