<template>
    <header class="bg-gray-900 sm:flex sm:justify-between sm:items-center">
        <div class="flex items-center justify-between pl-8 py-2 sm:pl-20">
            <div>
                <ul class="flex justify-center text-white">
                    <li class="px-1"><a href="https://www.facebook.com/profile.php?id=100070268447385"><i class="fab fa-facebook-square text-xl"></i></a></li>
                    <li class="px-1"><a href="https://www.youtube.com/channel/UCkt7sAmwH0KLY68PrLu1B9g"><i class="fab fa-youtube-square text-xl"></i></a></li>
                </ul>
            </div>
            <div class="pr-8 sm:hidden">
                <button @click="isOpen = !isOpen" type="button" class="text-gray-500 hover:text-white focus:text-white focus:outline-none">
                    <svg class="h-8 w-8 fill-current" viewBox="0 0 24 24">
                        <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                        <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                    </svg>
                </button>
            </div>
        </div>

        <div :class="isOpen ? 'block' : 'hidden'" class="p-2 sm:flex sm:pr-24">
            <scroll-component href="#statement-of-faith" class="mt-1 block px-2 py-1 text-white rounded font-semibold hover:bg-gray-800">Statement of Faith</scroll-component>
            <scroll-component href="#gallery" class="mt-1 block px-2 py-1 text-white rounded font-semibold hover:bg-gray-800">Gallery</scroll-component>
            <scroll-component href="#contact-us" class="mt-1 block px-2 py-1 text-white rounded font-semibold hover:bg-gray-800">Contact Us</scroll-component>
            <a v-if="appLang == 'ne'" href="/lang/en" class="mt-1 block px-2 py-1 text-white rounded font-semibold hover:bg-gray-800">
                 <span class="inline-flex items-center justify-center px-2 py-1 text-base leading-none text-green-100 bg-green-600 rounded-full">
                    View in English
                </span>
            </a>
            <a v-if="appLang == 'en'" href="/lang/ne" class="mt-1 block px-2 py-1 text-white rounded font-semibold hover:bg-gray-800">
                <span class="inline-flex items-center justify-center px-2 py-1 text-base leading-none text-green-100 bg-green-600 rounded-full">
                    नेपालीमा हेर्नुहोस
                </span>
            </a>
        </div>
    </header>

</template>

<script>
    export default {

        data() {
            return {
                isOpen: false,
                appLang: window.appLang,
            }
        }
    }
</script>
