<template>
    <select name="" class="">
        <option>Language</option>
        <option><a>English</a></option>
        <option>Nepali</option>
    </select>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>
