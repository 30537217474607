require('./bootstrap');


window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('ncca-header-component', require('./components/NccaHeaderComponent.vue').default);
Vue.component('scroll-component', require('./components/ScrollComponent.vue').default);
Vue.component('lightbox-component', require('./components/LightboxComponent.vue').default);
Vue.component('video-gallery-component', require('./components/VideoGalleryComponent.vue').default);
Vue.component('language-selector-component', require('./components/LanguageSelectorComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

class Errors {
    constructor() {
        this.errors = {};
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }


    has(field) {
        return this.errors.hasOwnProperty(field);
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    record(errors) {
        this.errors = errors;
    }

    clear(field) {

        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};

    }
}

class Form {
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];

        }

        this.errors = new Errors();
    }

    data() {
        let data = Object.assign({}, this);
        delete data.originalData;
        delete data.errors;

        return data;
    }

    reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clear();
    }

    submit(requestType, url) {

        return new Promise((resolve, reject) => {

            axios[requestType](url, this.data())
                .then(response => {
                    this.onSuccess(response.data);

                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data.errors);

                })
        });
    }

    onSuccess(data) {
        // alert(data.message);

        this.reset();
    }

    onFail(errors) {
        this.errors.record(errors)
    }

}

const app = new Vue({
    el: '#app',

    data: {
        form: new Form({
            fullname: '',
            contact: '',
            email: '',
            subject: '',
            message: ''
        }),
        message: '',
    },

    methods: {
        processContactForm() {
            this.form.submit('post', '/contact')
                .then(data => {
                    this.message = data.message;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
});


